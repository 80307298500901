import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import axios from "axios";
import { LOCAL_URL } from "../Constants";
import { useState } from "react";
import OrderPayment from "../Payment/OrderPayment";

export default function Pricing() {
  const [liecenseTypes, setLiecenseTypes] = React.useState([
    {
      title: "Basic",
      price: 10,
      description: [
        "10 users included",
        "2 GB of storage",
        "Help center access",
        "Email support",
      ],
    },
    {
      title: "Professional",
      subheader: "Most popular",
      price: 20,
      description: [
        "20 users included",
        "10 GB of storage",
        "Help center access",
        "Priority email support",
      ],
    },
    {
      title: "Enterprise",
      price: 30,
      description: [
        "30 users included",
        "15 GB of storage",
        "Help center access",
        "Phone & email support",
      ],
    },
  ]);

  // React.useEffect(() => {
  //   axios
  //     .get(LOCAL_URL + "/api/payment/liecense_types")
  //     .then((response) => {
  //       console.log(response.data);
  //       setLiecenseTypes(response.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // });

  const [clientId, setClientId] = useState(
    "AcFDMW9Pad5cxE9OQ0oaIYdxuiJzc2BC339dMUuLyt14gxB7xI-bSH3WTDwrLS0NHtrGLkqCdu5ztpcz"
  ); // Fetch from server-side

  const handlePayPalCheckout = async () => {
    try {
      // Send product details to server-side endpoint
      const response = await fetch("/api/payment/create-payment", {
        method: "POST",
        body: JSON.stringify({
          quantity: 1,
          price: 20,
          currency: "USD",
          item: "Monthly",
        }),
      });

      const { approvalUrl } = await response.json();

      // Redirect user to PayPal for payment
      window.location.href = approvalUrl;
    } catch (error) {
      console.error("Error creating payment:", error);
      // Handle errors gracefully
    }
  };

  return (
    <Container
      id="pricing"
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Pricing
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Quickly build an effective pricing table for your potential customers
          with this layout. <br />
          It&apos;s built with default Material UI components with little
          customization.
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {liecenseTypes.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === "Enterprise" ? 12 : 6}
            md={4}
          >
            <Card
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                gap: 4,
                border: tier.title === "Professional" ? "1px solid" : undefined,
                borderColor:
                  tier.title === "Professional" ? "primary.main" : undefined,
                background:
                  tier.title === "Professional"
                    ? "linear-gradient(#033363, #021F3B)"
                    : undefined,
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: tier.title === "Professional" ? "grey.100" : "",
                  }}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.title === "Professional" && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label={tier.subheader}
                      size="small"
                      sx={{
                        background: (theme) =>
                          theme.palette.mode === "light" ? "" : "none",
                        backgroundColor: "primary.contrastText",
                        "& .MuiChip-label": {
                          color: "primary.dark",
                        },
                        "& .MuiChip-icon": {
                          color: "primary.dark",
                        },
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    color:
                      tier.title === "Professional" ? "grey.50" : undefined,
                  }}
                >
                  <Typography component="h3" variant="h2">
                    ${tier.price}
                  </Typography>
                  <Typography component="h3" variant="h6">
                    &nbsp; per month
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: "grey.500",
                  }}
                />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: "flex",
                      gap: 1.5,
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color:
                          tier.title === "Professional"
                            ? "primary.light"
                            : "primary.main",
                      }}
                    />
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color:
                          tier.title === "Professional"
                            ? "grey.200"
                            : undefined,
                      }}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <OrderPayment
                  sx={{
                    color: "primary.main",
                    borderColor: "primary.main",
                    "&:hover": {
                      borderColor: "primary.dark",
                    },
                  }}
                  order={{
                    payment_ref_id: "string123",
                    quantity: 1,
                    price: tier.price,
                    item: "string",
                  }}
                />
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

import * as React from "react";
import { LOCAL_URL } from "../Constants";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import DownloadIcon from "@mui/icons-material/Download";
import PaymentIcon from "@mui/icons-material/Payment";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import UserTable from "./UserTable";
import TextField from "@mui/material/TextField";
import AppBar from "@mui/material/AppBar";
import TabPanel from "@mui/material/Tabs";

import { List, ListItem, Paper } from "@mui/material";
import AddUser from "./AddUser";
import UserLogins from "./UserLogins";
import Pricing from "../home/Pricing";
import Payment from "@mui/icons-material/Payment";
import PaymentHistory from "../Payment/PaymentHistory";
import Billing from "../Payment/Billing";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      height="100%"
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AdminProfile() {
  const [userDetails, setUserDetails] = useState({});
  const [organizationDetails, setOrganizationDetails] = useState({});
  const [value, setValue] = React.useState(0);
  const [valueBP, setValueBP] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeBP = (event, newValue) => {
    setValueBP(newValue);
  };

  const navigate = useNavigate();
  const handleUnauthorized = () => {
    console.log("Handling unuthorized");
    navigate("/login");
  };

  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  if (!token || !user_id) {
    console.log("Error: Unauthorized");
    handleUnauthorized();
  }

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const message = localStorage.getItem("message");
  const payment = localStorage.getItem("payment");

  React.useEffect(() => {
    axios
      .get(
        `${LOCAL_URL}/api/auth/organization/admin/${user_id}/details`,
        config
      )
      .then((response) => {
        if ([400, 401, 403, 404].includes(response.status)) {
          handleUnauthorized();
        }
        const { organization, user } = response.data;
        console.log("user:", user);
        console.log("organization:", organization);
        setUserDetails(user);
        setOrganizationDetails(organization || {});
      })
      .catch((error) => {
        console.error("Error:", error);
        if ([400, 401, 403, 404].includes(error.response.status)) {
          handleUnauthorized();
        }
      });
  }, []);

  const downloadTxtFile = () => {
    axios.get(`${LOCAL_URL}/api/software`, config).then((response) => {
      console.log("Success:", response.data);
      const element = document.createElement("a");
      const file = new Blob([response.data], {
        type: "text/zip",
      });
      element.href = URL.createObjectURL(file);
      element.download = "QuestSQLAI.zip";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    });
  };

  const [addUserOpen, setAddUserOpen] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [user, setUser] = React.useState({});
  const handleAlertClose = () => {
    setAddUserOpen(false);
  };
  const callAddUpdateUser = (user) => {
    setUsers(
      ...users,
      (users[user.username] = {
        username: user.username,
        password: user.password,
        email: user.email,
        role: user.role,
        status: user.status,
      })
    );
    setAddUserOpen(false);
  };

  return (
    <Grid container spacing={2}>
      <AddUser
        handleClose={handleAlertClose}
        callAddUpdateUser={callAddUpdateUser}
        open={addUserOpen}
        user={user}
      />
      <Grid item xs={12}>
        <Alert severity="warning" style={{ paddingBottom: "5px" }}>
          {message}
        </Alert>
      </Grid>
      <Grid item xs={12} sm={12}>
        <div
          className="ag-theme-quartz" // applying the grid theme
          style={{ height: 500 }} // the grid will fill the size of the parent container
        >
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Profile" {...a11yProps(0)} />
                <Tab label="Users" {...a11yProps(1)} />
                <Tab label="User Logins" {...a11yProps(2)} />
                <Tab label="Billing & Payments" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Paper style={{ padding: 5 }}>
                <Grid container spacing={5} style={{ padding: 15 }}>
                  <Grid item xs={4}>
                    <Card>
                      <CardContent>
                        <Typography variant="h4">Profile</Typography>
                        <List>
                          <Divider component="li" />
                          <ListItem>
                            <TextField
                              id="outlined-basic"
                              label="Name"
                              variant="standard"
                              fullWidth
                              defaultValue={
                                userDetails.first_name +
                                " " +
                                userDetails.last_name
                              }
                              disabled
                            />
                          </ListItem>
                          <ListItem>
                            <TextField
                              id="outlined-basic"
                              label="Username"
                              variant="standard"
                              fullWidth
                              defaultValue={userDetails.email}
                              disabled
                            />
                          </ListItem>
                          <ListItem>
                            <TextField
                              id="outlined-basic"
                              label="Phone"
                              variant="standard"
                              fullWidth
                              defaultValue={userDetails.phone}
                              disabled
                            />
                          </ListItem>
                          <ListItem>
                            <TextField
                              id="outlined-basic"
                              label="Created At"
                              variant="standard"
                              fullWidth
                              defaultValue={userDetails.created_at}
                              disabled
                            />
                          </ListItem>
                          <ListItem>
                            <TextField
                              id="outlined-basic"
                              label="Mac Address"
                              variant="standard"
                              fullWidth
                              defaultValue={userDetails.mac_address}
                              disabled
                            />
                          </ListItem>
                          <ListItem>
                            <TextField
                              id="outlined-basic"
                              label="Role"
                              variant="standard"
                              fullWidth
                              defaultValue={userDetails.role}
                              disabled
                            />
                          </ListItem>
                          <ListItem>
                            <TextField
                              id="outlined-basic"
                              label="Account Type"
                              variant="standard"
                              fullWidth
                              defaultValue={userDetails.account_type}
                              disabled
                            />
                          </ListItem>
                        </List>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card>
                      <CardContent>
                        <Typography variant="h4">Organization</Typography>
                        <List>
                          <Divider component="li" />
                          <ListItem>
                            <TextField
                              label="Name"
                              variant="standard"
                              fullWidth
                              defaultValue={organizationDetails.name}
                              disabled
                            />
                          </ListItem>
                          <ListItem>
                            <TextField
                              label="Phone"
                              variant="standard"
                              fullWidth
                              defaultValue={organizationDetails.phone}
                              disabled
                            />
                          </ListItem>
                          <ListItem>
                            <TextField
                              label="Address"
                              variant="standard"
                              fullWidth
                              defaultValue={
                                (organizationDetails.city || "") +
                                ", " +
                                (organizationDetails.state || "") +
                                ", " +
                                (organizationDetails.country || "")
                              }
                              disabled
                            />
                          </ListItem>
                        </List>
                      </CardContent>
                      <CardActions
                        sx={{ display: { xs: "none", md: "flex" } }}
                      ></CardActions>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card>
                      <CardContent>
                        <Typography variant="h4">Actions</Typography>
                        <List>
                          <Divider component="li" />
                          <ListItem>
                            <Button
                              id="downloadBtn"
                              variant="contained"
                              startIcon={<DownloadIcon />}
                              onClick={downloadTxtFile}
                              value="download"
                              height="100px"
                              color="success"
                              disabled={payment === "false"}
                            >
                              Download Quest SQL AI
                            </Button>
                          </ListItem>
                          <ListItem>
                            <Button
                              id="downloadBtn"
                              variant="contained"
                              startIcon={<PaymentIcon />}
                              value="payment"
                              height="100px"
                              color="primary"
                              disabled={payment === "false"}
                              onClick={() => {
                                window.location.href = "/pricing";
                              }}
                            >
                              Make Payment
                            </Button>
                          </ListItem>
                        </List>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Paper>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Paper style={{ padding: 5 }}>
                <Grid container spacing={5} style={{ padding: 15 }}>
                  <Grid item xs={12}>
                    <UserTable userId={userDetails.id} />
                  </Grid>
                </Grid>
              </Paper>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Paper style={{ padding: 5 }}>
                <Grid container spacing={5} style={{ padding: 15 }}>
                  <Grid item xs={12}>
                    <UserLogins userId={userDetails.id} />
                  </Grid>
                </Grid>
              </Paper>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <Paper style={{ padding: 5 }}>
                <Grid container spacing={5} style={{ padding: 15 }}>
                  <Grid item xs={12}>
                    <AppBar position="static">
                      <Tabs
                        value={valueBP}
                        onChange={handleChangeBP}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                      >
                        <Tab label="Billing" {...a11yProps(0)} />
                        <Tab label="Payment History" {...a11yProps(1)} />
                      </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                      <Billing />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <PaymentHistory />
                    </TabPanel>
                  </Grid>
                </Grid>
              </Paper>
            </CustomTabPanel>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}

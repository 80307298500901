import React, { useState } from "react";
import { Button } from "@mui/material";
import axios from "axios";
import { LOCAL_URL } from "../Constants";
import { v4 as uuidv4 } from "uuid";

export default function OrderPayment({ order }) {
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("token");

  const handlePayPalCheckout = async () => {
    try {
      order.payment_ref_id = uuidv4();
      console.log("Order:", order);

      await axios
        .post(`${LOCAL_URL}/api/pay/create-payment`, order)
        .then((response) => {
          console.log("Success:", response.data);
          const { approvalUrl } = response.data;

          // Redirect user to PayPal for payment
          window.location.href = approvalUrl;
        })
        .error((error) => {
          console.error("Error:", error);
        });

      // const response = await fetch("/api/pay/create-payment", {
      //   method: "POST",
      //   body: JSON.stringify(order),
      // });

      // const { approvalUrl } = await response.json();

      // // Redirect user to PayPal for payment
      // window.location.href = approvalUrl;
    } catch (error) {
      console.error("Error creating payment:", error);
      // Handle errors gracefully
    }
  };

  return (
    <div>
      <Button
        fullWidth
        onClick={(data, actions) => handlePayPalCheckout(data, actions)}
        variant="contained"
        color="success"
      >
        Pay
      </Button>
    </div>
  );
}

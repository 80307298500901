import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import { Grid } from "@mui/material";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

export default function ShowPayment({ open, handleClose, payment }) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"lg"}
      >
        <DialogTitle id="alert-dialog-title">{"Payment Details"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Paper style={{ padding: 5 }}>
              <Grid container spacing={5} style={{ padding: 15 }}>
                <Grid item xs={12}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Payment ID</TableCell>
                        <TableCell>{payment.payment_id}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Payment Time</TableCell>
                        <TableCell>{payment.payment_time}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Payment Amount</TableCell>
                        <TableCell>{payment.payment_amount}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Payment Quantity</TableCell>
                        <TableCell>{payment.payment_quantity}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Total Price</TableCell>
                        <TableCell>{payment.total_price}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Payment Status</TableCell>
                        <TableCell>{payment.payment_status}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Paper>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

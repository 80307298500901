import * as React from "react";
import { LOCAL_URL } from "../Constants";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import axios from "axios";
import { Alert, Typography } from "@mui/material";
import AlertDialog from "../AlertDialog";

const initialRows = [];

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      { id, username: "", password: "", role: "Practitioner", isNew: true },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "username" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

export default function UserTable({ userId }) {
  const [rows, setRows] = React.useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deleteAction, setDeleteAction] = React.useState(false);

  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  if (!token || !user_id) {
    console.log("Error: Unauthorized");
  }

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  React.useEffect(() => {
    axios
      .get(
        `${LOCAL_URL}/api/auth/organization/${user_id}/practitioners`,
        config
      )
      .then((response) => {
        if ([400, 401, 403, 404].includes(response.status)) {
          console.log("Error: ", response.data.message);
        }
        console.log("Response: ", response.data);
        setRows(response.data);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }, []);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setDeleteOpen(true);
    if (deleteAction) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: "first_name",
      headerName: "First Name",
      width: 100,
      editable: true,
    },
    { field: "last_name", headerName: "Last Name", width: 100, editable: true },
    {
      field: "email",
      headerName: "Email",
      type: "email",
      width: 250,
      editable: false,
    },
    { field: "phone", headerName: "Phone", width: 150, editable: true },
    {
      field: "mac_address",
      headerName: "Mac Address",
      width: 200,
      editable: false,
    },
    {
      field: "role",
      headerName: "Role",
      width: 100,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Practitioner", "Admin"],
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 50,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <>
      <AlertDialog
        open={deleteOpen}
        setOpen={setDeleteOpen}
        title={"Delete User"}
        message={"Are you sure, you really want to delete this user"}
        action={setDeleteAction}
      />
      <Typography variant="h6">Practitioners</Typography>
      <Alert severity="info">
        User Count :<b> {rows.length} </b>; Billed Users :<b> {rows.length} </b>
      </Alert>
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true },
        }}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
      />
    </>
  );
}

import * as React from "react";
import { LOCAL_URL } from "../Constants";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminProfile from "./AdminProfile";
import UserProfile from "./UserProfile";

export default function Profile() {
  const navigate = useNavigate();
  const handleUnauthorized = () => {
    console.log("Handling unuthorized");
    navigate("/login");
  };

  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  if (!token || !user_id) {
    console.log("Error: Unauthorized");
    handleUnauthorized();
  }

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const username = localStorage.getItem("username");
  const macAddress = localStorage.getItem("macAddress");
  const message = localStorage.getItem("message");
  const payment = localStorage.getItem("payment");

  const userDetails = {
    id: 13,
    username: username,
    macAddress: macAddress,
    message: message,
    payment: payment,
    accountType: "orgnisational",
  };

  return (
    <div>
      {userDetails.accountType === "orgnisational" ? (
        <AdminProfile userDetails={userDetails} />
      ) : (
        <UserProfile userDetails={userDetails} />
      )}
    </div>
  );
}

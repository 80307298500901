import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import { Grid } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SendIcon from "@mui/icons-material/Send";

export default function AddUser({
  open,
  handleClose,
  callAddUpdateUser,
  user,
}) {
  const [username, setUsername] = React.useState(user.username);
  const [password, setPassword] = React.useState(user.password);
  const [email, setEmail] = React.useState(user.email);
  const [role, setRole] = React.useState(user.role);
  const [status, setStatus] = React.useState(user.status);

  const callBackFunction = () => {
    callAddUpdateUser({
      username: username,
      password: password,
      email: email,
      role: role,
      status: status,
    });
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"lg"}
      >
        <DialogTitle id="alert-dialog-title">{"Add User"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Paper style={{ padding: 5 }}>
              <Grid container spacing={5} style={{ padding: 15 }}>
                <Grid item xs={6}>
                  <TextField
                    id={"username"}
                    label={"Username"}
                    variant="outlined"
                    fullWidth
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id={"password"}
                    label={"Password"}
                    variant="outlined"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id={"email"}
                    label={"Email"}
                    variant="outlined"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id={"role"}
                    label={"Role"}
                    variant="outlined"
                    fullWidth
                    value={role}
                    select
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <MenuItem value="user">User</MenuItem>
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="AP">AP</MenuItem>
                    <MenuItem value="AR">AR</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id={"status"}
                    label={"Status"}
                    variant="outlined"
                    fullWidth
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Paper>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={callBackFunction}
            autoFocus
            startIcon={<SendIcon />}
            variant="outlined"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

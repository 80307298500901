import React, { useState } from "react";
import axios from "axios";
import { LOCAL_URL } from "../Constants";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button, Typography } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { GridActionsCellItem } from "@mui/x-data-grid";
import ShowPayment from "./ShowPayment";

export default function Billing() {
  const [open, setOpen] = useState(false);
  const [invoice, showInvoice] = useState({});
  const [invoices, setInvoices] = useState([]);
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("token");

  const columns = [
    {
      field: "invoice_id",
      headerName: "Invoice ID",
      width: 300,
      editable: true,
    },
    { field: "due_date", headerName: "Due Date", width: 250 },
    {
      field: "amount_due",
      headerName: "Amount",
    },
    {
      field: "user_quantity",
      headerName: "User Count",
    },
    {
      field: "is_paid",
      headerName: "Total Amount",
    },
    {
      field: "status",
      headerName: "Status",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={<RemoveRedEyeIcon />}
            label="Show Details"
            sx={{
              color: "primary.main",
            }}
            onClick={handleShowDetails(row)}
          />,
        ];
      },
    },
  ];

  const handleShowDetails = (pay) => () => {
    console.log("Payment:", pay);
    showInvoice(pay);
    axios.get(`${LOCAL_URL}/api/payments/invoice/${pay.id}/pdf`).then(
      (response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "invoice.pdf");
        document.body.appendChild(fileLink);
        fileLink.click();
        fileLink.parentNode.removeChild(fileLink);
      },
      (error) => {
        console.error("Error fetching invoice:", error);
      }
    );
  };

  React.useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await axios.get(
          `${LOCAL_URL}/api/payments/invoice/all`
        );
        setInvoices(response.data);
      } catch (error) {
        console.error("Error fetching invoices:", error);
      }
    };

    fetchInvoices();
  }, []);

  return (
    <div>
      <div className="example-header">
        <Typography variant="h6">Invoices</Typography>
      </div>
      <DataGrid
        rows={invoices}
        columns={columns}
        pagination
        pageSizeOptions={[5, 10, 25, 100]}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </div>
  );
}

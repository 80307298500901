import React, { useState } from "react";
import { Button } from "@mui/material";
import { json, useLocation } from "react-router-dom";
import axios from "axios";
import { LOCAL_URL } from "../Constants";

export default function OrderPaymentSuccess() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const payment_ref_id = queryParams.get("payment_ref_id");
  const payment_id = queryParams.get("paymentId");
  const PayerID = queryParams.get("PayerID");
  const user_id = queryParams.get("user_id");
  const jwt_token = queryParams.get("jwt_token");

  React.useEffect(() => {
    console.log("Payment ID:", payment_id);
    console.log("Payment Ref ID:", payment_ref_id);
    console.log("User ID:", user_id);
    console.log("JWT Token:", jwt_token);

    axios.defaults.headers.common["Authorization"] = "Bearer " + jwt_token;

    const payload = {
      paymentId: payment_id,
      payment_ref_id: payment_ref_id,
      PayerID: PayerID,
      user_id: user_id,
    };

    axios
      .post(`${LOCAL_URL}/api/pay/success`, payload)
      .then((response) => {
        // if ([400, 401, 403, 404].includes(response.status)) {
        //   handleUnauthorized();
        // }
        console.log("Success:", response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        // if ([400, 401, 403, 404].includes(error.response.status)) {
        //   handleUnauthorized();
        // }
      });
  }, []);

  const handleBacktoProfile = () => {
    window.location.href = `/profile`;
  };

  return (
    <div>
      <h1>Order Success</h1>
      <p>Your order has been successfully placed.</p>
      <p>Order ID: {payment_id}</p>
      <Button variant="contained" color="success" onClick={handleBacktoProfile}>
        Back to Profile
      </Button>
    </div>
  );
}

import React, { useState } from "react";
import axios from "axios";
import { LOCAL_URL } from "../Constants";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button, Typography } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { GridActionsCellItem } from "@mui/x-data-grid";
import ShowPayment from "./ShowPayment";

export default function PaymentHistory() {
  const [open, setOpen] = useState(false);
  const [payment, setPayment] = useState({});
  const [payments, setPayments] = useState([]);
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("token");

  const columns = [
    {
      field: "payment_id",
      headerName: "Payment ID",
      width: 300,
      editable: true,
    },
    { field: "payment_time", headerName: "Payment Time", width: 250 },
    {
      field: "payment_amount",
      headerName: "Amount",
    },
    {
      field: "payment_quantity",
      headerName: "User Count",
    },
    {
      field: "total_price",
      headerName: "Total Amount",
    },
    {
      field: "payment_status",
      headerName: "Status",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={<RemoveRedEyeIcon />}
            label="Show Details"
            sx={{
              color: "primary.main",
            }}
            onClick={handleShowDetails(row)}
          />,
        ];
      },
    },
  ];

  const handleShowDetails = (pay) => () => {
    console.log("Payment:", pay);
    setPayment(pay);
    setOpen(true);
  };

  React.useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await axios.get(`${LOCAL_URL}/api/payments/history`);
        setPayments(response.data);
      } catch (error) {
        console.error("Error fetching payments:", error);
      }
    };

    fetchPayments();
  }, []);

  return (
    <div>
      <ShowPayment
        open={open}
        handleClose={() => setOpen(false)}
        payment={payment}
      />
      <div className="example-header">
        <Typography variant="h6">Payments</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            window.location.href = "/pricing";
          }}
        >
          Make Payment
        </Button>
      </div>
      <DataGrid
        rows={payments}
        columns={columns}
        pagination
        pageSizeOptions={[5, 10, 25, 100]}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </div>
  );
}

import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { LOCAL_URL } from "./Constants";
import { Alert, Divider } from "@mui/material";
import { MenuItem, Select } from "@mui/material";
import { useNavigate } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { COUNTIRES } from "./data/country";

const defaultTheme = createTheme();

const Registration = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const [orgName, setOrgName] = useState("");
  const [orgCity, setOrgCity] = useState("");
  const [orgState, setOrgState] = useState("");
  const [orgCountry, setOrgCountry] = useState("US");
  const [orgPhone, setOrgPhone] = useState("");

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(username)) {
      setMessage("Invalid email address, please try again!");
      return;
    }

    if (confirmPassword !== password) {
      setMessage("Password did not match, please try again!");
      return;
    }

    if (password.length < 8) {
      setMessage("Password must be at least 8 characters long!");
      return;
    }

    if (!orgName || !orgCity || !orgState || !orgCountry || !orgPhone) {
      setMessage("Please fill in all the organization details!");
      return;
    }

    try {
      const response = await axios.post(LOCAL_URL + "/api/auth/register", {
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        email: username,
        password: password,
        org_name: orgName,
        org_city: orgCity,
        org_state: orgState,
        org_country: orgCountry,
        org_phone: orgPhone,
      });
      setMessage(response.data.message);
      navigate("/login");
    } catch (error) {
      console.error("Registration failed:", error.response.data.message);
      setMessage(error.response.data.message);
    }
  };

  return (
    <div>
      <ThemeProvider theme={defaultTheme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: 'url("../assets/banner.jpg")',
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                mt: 8,
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img width={300} src="assets/QS.png" alt="QuestAI" />
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign up
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <Paper
                  elevation={6}
                  style={{
                    padding: 20,
                  }}
                >
                  <Alert
                    severity="error"
                    style={{ display: message ? "block" : "none" }}
                  >
                    {message}
                  </Alert>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} elevation={6}>
                      <Typography variant="h6">Personal Information</Typography>
                      <Divider />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="First Name"
                        type="text"
                        id="first_name"
                        label="First Name"
                        name="first_name"
                        autoComplete="first_name"
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="Last Name"
                        type="text"
                        id="last_name"
                        label="Last Name"
                        name="last_name"
                        autoComplete="last_name"
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Phone Number"
                        type="email"
                        id="phone"
                        label="Phone Number"
                        name="phone"
                        autoComplete="phone"
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Email"
                        type="email"
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        autoComplete="new-password"
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        id="confirmPassword"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm Password"
                        autoComplete="new-password"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6">
                        Organization Information
                      </Typography>
                      <Divider />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        onChange={(e) => setOrgName(e.target.value)}
                        placeholder="First Name"
                        type="text"
                        id="org_name"
                        label="Organization Name"
                        name="org_name"
                        autoComplete="org_name"
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        onChange={(e) => setOrgCity(e.target.value)}
                        placeholder="Organization City"
                        type="text"
                        id="org_city"
                        label="Organization City"
                        name="org_city"
                        autoComplete="org_city"
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        onChange={(e) => setOrgState(e.target.value)}
                        placeholder="Oranization State"
                        type="text"
                        id="org_state"
                        label="Oranization State"
                        name="org_state"
                        autoComplete="org_state"
                      />
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={orgCountry}
                        label="Organization Country"
                        onChange={(e) => setOrgCountry(e.target.value)}
                        fullWidth
                        variant="outlined"
                      >
                        {COUNTIRES.map((country) => (
                          <MenuItem key={country.code} value={country.code}>
                            {country.name}
                          </MenuItem>
                        ))}
                      </Select>

                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        onChange={(e) => setOrgPhone(e.target.value)}
                        placeholder="Oranization Phone"
                        type="text"
                        id="org_phone"
                        label="Oranization Phone"
                        name="org_phone"
                        autoComplete="org_phone"
                        select
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onSubmit={handleSubmit}
                  >
                    Sign Up
                  </Button>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Link href="/login" variant="body2">
                        {"Sign in"}
                        <OpenInNewIcon />
                      </Link>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
};

export default Registration;

import * as React from "react";
import { LOCAL_URL } from "../Constants";
import Box from "@mui/material/Box";
import axios from "axios";
import { TextField, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const initialRows = [];

export default function UserLogins({ userId }) {
  const gridRef = React.useRef();
  const [rows, setRows] = React.useState(initialRows);

  const onFilterTextBoxChanged = React.useCallback(() => {
    gridRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value
    );
  }, []);

  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  if (!token || !user_id) {
    console.log("Error: Unauthorized");
  }

  const columns = [
    {
      field: "first_name",
      headerName: "First Name",
      width: 100,
      editable: true,
    },
    { field: "last_name", headerName: "Last Name", width: 100, editable: true },
    {
      field: "email",
      headerName: "Email",
      type: "email",
      width: 250,
      editable: false,
    },
    {
      field: "login_time",
      headerName: "Login Time",
      width: 150,
      editable: true,
    },
    {
      field: "logout_time",
      headerName: "Logout Time",
      width: 150,
      editable: true,
    },
    {
      field: "mac_address",
      headerName: "Login Device",
      width: 200,
      editable: false,
      valueFormatter: (value) => {
        return value.value === "00:00:00:00:00:00"
          ? "Cloud Login"
          : "Desktop Login";
      },
    },
  ];

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  React.useEffect(() => {
    axios
      .get(
        `${LOCAL_URL}/api/auth/organization/${user_id}/practitioners/logins`,
        config
      )
      .then((response) => {
        if ([400, 401, 403, 404].includes(response.status)) {
          console.error("Error:", response);
        }
        setRows(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        if ([400, 401, 403, 404].includes(error.response.status)) {
          console.error("Error:", error);
        }
        // handleUnauthorized();
      });
  }, []);

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <div className="example-header">
        <Typography variant="h6">Practitioners</Typography>
      </div>
      <DataGrid
        rows={rows}
        columns={columns}
        pagination
        pageSize={10}
        pageSizeOptions={[5, 10, 25, 100]}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </Box>
  );
}
